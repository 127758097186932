import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { getJwt } from "../../Redux/action/login";
import { toasterMessage } from "../../utils/toast-util";
export class Login extends Component {
  constructor(props) {
    super();
    this.state = {
      email: "",
      password: "",
      token: "",
      touched: false,
      message: true,
    };
  }
  onChangeEmail = (e) => {
    this.setState({ email: e.target.value, touched: true });
  };
  onChangePassword = (e) => {
    this.setState({ password: e.target.value, touched: true });
  };
  componentDidUpdate() {
    if (localStorage.jwt) {
      this.props.history.push("/dashboard");
    }
  }

  componentDidMount() {
    // if (localStorage.loginType) {
    // localStorage.removeItem("loginType");
    // }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ touched: false });
    await this.props.getJwt({
      email: this.state.email,
      password: this.state.password,
    });
  };

  render() {
    return (
      <div>
        <div className='d-flex align-items-center auth px-0'>
          <div className='row w-100 mx-0'>
            <div className='col-lg-4 mx-auto'>
              <div className='auth-form-light text-left py-5 px-4 px-sm-5'>
                <h4>Hello! let's get started</h4>
                <h6 className='font-weight-light'>Log in to continue.</h6>
                <Form className='pt-3' onSubmit={(e) => this.handleSubmit(e)}>
                  <Form.Group className='d-flex search-field'>
                    <Form.Control
                      type='email'
                      required
                      placeholder='Email'
                      size='lg'
                      className='h-auto'
                      id='email'
                      onChange={(e) => this.onChangeEmail(e)}
                    />
                  </Form.Group>
                  {/* <Form.Group className="d-flex search-field"> */}
                  <div class='input-group input-group-merge form-password-toggle'>
                    <Form.Control
                      type={this.state.showpasswordNew ? "text" : "password"}
                      // style={{ "border-right": "0" }}
                      className='form-control'
                      required
                      id='exampleInputUsername2'
                      placeholder='New Password'
                      onChange={(e) => this.onChangePassword(e)}
                    />
                    <div
                      class='input-group-append'
                      onClick={() => {
                        this.setState({
                          showpasswordNew: !this.state.showpasswordNew,
                        });
                      }}
                    >
                      <span
                        class='input-group-text cursor-pointer'
                        style={{
                          "background-color": "white",
                          "padding-right": "10px",
                          "padding-left": "10px",
                        }}
                      >
                        <i
                          className={`feather font-small-4 fa ${
                            this.state.showpasswordNew
                              ? "fa-eye-slash"
                              : "fa-eye"
                          }`}
                        ></i>
                      </span>
                    </div>
                    <Form.Control.Feedback type='invalid'>
                      Please provide a Password.
                    </Form.Control.Feedback>
                  </div>
                  <div className='mt-3 '>
                    <button
                      type='submit'
                      className='btn btn-block yellow-btn btn-lg font-weight-medium auth-form-btn'
                    >
                      LOG IN{" "}
                    </button>
                  </div>
                  <div className='my-2 d-flex justify-content-between align-items-center'>
                    <Link
                      className='auth-link text-black'
                      to='/forgot-password'
                    >
                      {/* <Link href="!#" onClick={event => event.preventDefault()} className="auth-link text-black"> */}
                      Forgot password?
                      {/* </a> */}
                    </Link>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    token: state.loginReducer.token,
    message: state.loginReducer.message,
    loggedIn: state.loginReducer.loggedIn,
    loginFail: state.loginReducer.loginFail,
  };
}

export default connect(mapStateToProps, { getJwt })(Login);
