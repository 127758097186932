import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { PrivateRoute, PrivateRoute1 } from "../routes/PrivateRoute";
import Spinner from "../app/shared/Spinner";
import ArtistList from "./artist-management/artistList";
import SupplierList from "./supplier-management/supplierList";
import AddArtist from "./artist-management/addArtist";
import AddSupplier from "./supplier-management/addSupplier";
import CreateArtistPost from "./artist-management/addArtistPosts";
import MenuList from "./menu-management/menuList";
import SubAdminList from "./sub-admin-management/subAdminList";
import CreateSubadmin from "./sub-admin-management/addSubAdmin";
import AddArtistType from "./categories-management/addArtistType";
import AddSpecialties from "./categories-management/addSpecialties";
import AddTag from "./categories-management/addTag";
import AddGenre from "./categories-management/addGenres";
import CreatePage from "./page-management/createPage";
import ContentList from "./content-management/contentList";
import CreateContent from "./content-management/createContent";
import RolesList from "./roles-permission/rolesList";
import CreateRole from "./roles-permission/createRole";
import { connect } from "react-redux";
import CreateMenu from "./menu-management/createMenu";
import PageLayoutManagement from "./page-layout-management/page-layout-management";
import CreateSubMenu from "./menu-management/createSubMenu";
import CreatePageLayout from "./page-layout-management/create-page-layout";
import EnquiryManagement from "./enquiry-management/enquiryManagement";
import NewsLetter from "./news-letter/newsLetter";
import Enquiries from "./enquiry-management/enquiry";
import ArtistLogin from "./artist-login/artistLogin";
import ArtistForget from "./artist-login/artistForget";
import ArtistOtp from "./artist-login/artistOtp";
import ArtistReset from "./artist-login/artistReset";
import ArtistRegister from "./artist-login/artistRegister";

import SupplierLogin from "./supplier-login/supplierLogin";
import SupplierForget from "./supplier-login/supplierForget";
import SupplierOtp from "./supplier-login/supplierOtp";
import SupplierRegister from "./supplier-login/supplierRegister";
import SupplierReset from "./supplier-login/supplierReset";

import Login from "./user-pages/Login";
import DashboardArtist from './dashboard-artist/DashboardArtist'
import Notifications from "./notification-artist/Notifications";
const Dashboard = lazy(() => import("./dashboard/Dashboard"));



const RtlLayout = lazy(() => import("./layout/RtlLayout"));
const ArtistLanding = lazy(() => import("../app/artist-login/artistSignup"));
const SupplierLanding = lazy(() => import("../app/supplier-login/supplierSignup"));
const Widgets = lazy(() => import("./widgets/Widgets"));
const UserManagement = lazy(() => import("./user-management/UserManagement"));
const UserDetail = lazy(() => import("./user-management/UserDetail"));
const MyAccount = lazy(() => import("./my-account/MyAccount"));
const CategoriesManagement = lazy(() =>
  import("./categories-management/categoriesManagement")
);
const MetaTagList = lazy(() => import("./metaTagManagement/TagList"));
const TagDetail = lazy(() => import("./metaTagManagement/tagDetails"));

const KanbanBoard = lazy(() => import("./apps/KanbanBoard"));
const Tickets = lazy(() => import("./apps/Tickets"));
const Chats = lazy(() => import("./apps/Chats"));
const TodoList = lazy(() => import("./apps/TodoList"));

const Accordions = lazy(() => import("./basic-ui/Accordions"));
const Buttons = lazy(() => import("./basic-ui/Buttons"));
const Badges = lazy(() => import("./basic-ui/Badges"));
const Breadcrumbs = lazy(() => import("./basic-ui/Breadcrumbs"));
const Dropdowns = lazy(() => import("./basic-ui/Dropdowns"));
const Modals = lazy(() => import("./basic-ui/Modals"));
const Progress = lazy(() => import("./basic-ui/Progress"));
const Paginations = lazy(() => import("./basic-ui/Paginations"));
const TabsPage = lazy(() => import("./basic-ui/TabsPage"));
const Typography = lazy(() => import("./basic-ui/Typography"));
const Tooltips = lazy(() => import("./basic-ui/Tooltips"));
const Popups = lazy(() => import("./basic-ui/Popups"));

const Dragula = lazy(() => import("./advanced-ui/Dragula"));
const Clipboard = lazy(() => import("./advanced-ui/Clipboards"));
const ContextMenu = lazy(() => import("./advanced-ui/ContextMenus"));
const Sliders = lazy(() => import("./advanced-ui/Sliders"));
const Carousel = lazy(() => import("./advanced-ui/Carousel"));
const Loaders = lazy(() => import("./advanced-ui/Loaders"));
const TreeView = lazy(() => import("./advanced-ui/TreeView"));

const BasicElements = lazy(() => import("./form-elements/BasicElements"));
const AdvancedElements = lazy(() => import("./form-elements/AdvancedElements"));
const Validation = lazy(() => import("./form-elements/Validation"));
const Wizard = lazy(() => import("./form-elements/Wizard"));

const BasicTable = lazy(() => import("./tables/BasicTable"));
const DataTable = lazy(() => import("./tables/DataTables"));
const ReactTable = lazy(() => import("./tables/ReactTable"));
const SortableTable = lazy(() => import("./tables/SortableTable"));

const VectorMap = lazy(() => import("./maps/VectorMap"));
const SimpleMap = lazy(() => import("./maps/SimpleMap"));



const Mdi = lazy(() => import("./icons/Mdi"));
const FlagIcons = lazy(() => import("./icons/FlagIcons"));
const FontAwesome = lazy(() => import("./icons/FontAwesome"));
const SimpleLine = lazy(() => import("./icons/SimpleLine"));
const Themify = lazy(() => import("./icons/Themify"));
const TypIcons = lazy(() => import("./icons/TypIcons"));

const TextEditors = lazy(() => import("./text-editors/TextEditors"));
const CodeEditor = lazy(() => import("./code-editor/CodeEditor"));

const ChartJs = lazy(() => import("./charts/ChartJs"));
const C3Charts = lazy(() => import("./charts/C3Charts"));
const Chartist = lazy(() => import("./charts/Chartist"));
const GoogleCharts = lazy(() => import("./charts/GoogleCharts"));
const SparkLineCharts = lazy(() => import("./charts/SparkLineCharts"));
const GuageChart = lazy(() => import("./charts/GuageChart"));

const Error404 = lazy(() => import("./error-pages/Error404"));
const Error500 = lazy(() => import("./error-pages/Error500"));

const Login2 = lazy(() => import("./user-pages/Login2"));
const ResetPassword = lazy(() => import("./user-pages/ResetPassword"));
const ForgotPassword = lazy(() => import("./user-pages/ForgotPassword"));
const Register1 = lazy(() => import("./user-pages/Register"));
const Register2 = lazy(() => import("./user-pages/Register2"));
const Lockscreen = lazy(() => import("./user-pages/Lockscreen"));

const LandingPage = lazy(() => import("./general-pages/LandingPage"));
const BlankPage = lazy(() => import("./general-pages/BlankPage"));
const Profile = lazy(() => import("./general-pages/Profile"));
const Faq = lazy(() => import("./general-pages/Faq"));
const Faq2 = lazy(() => import("./general-pages/Faq2"));
const NewsGrid = lazy(() => import("./general-pages/NewsGrid"));
const Timeline = lazy(() => import("./general-pages/Timeline"));
const SearchResults = lazy(() => import("./general-pages/SearchResults"));
const Portfolio = lazy(() => import("./general-pages/Portfolio"));
const UserListing = lazy(() => import("./general-pages/UserListing"));

const Invoice = lazy(() => import("./ecommerce/Invoice"));
const Pricing = lazy(() => import("./ecommerce/Pricing"));
const ProductCatalogue = lazy(() => import("./ecommerce/ProductCatalogue"));
const ProjectList = lazy(() => import("./ecommerce/ProjectList"));
const Orders = lazy(() => import("./ecommerce/Orders"));

const Email = lazy(() => import("./apps/Email"));
const Calendar = lazy(() => import("./apps/Calendar"));
const Gallery = lazy(() => import("./apps/Gallery"));
const PageList = lazy(() => import("./page-management/pagesList"));
// const DashboardArtist = lazy(() =>
//   import("./dashboard-artist/DashboardArtist")
// );





const MyProfile = lazy(() => import("./my-profile-artist/MyProfile"));
const MyProfileSupplier = lazy(() => import("./my-profile-supplier/MyProfile"));

class AppRoutes extends Component {
  render() {
    const token = localStorage.jwt ? true : false;
    let loginType = localStorage.loginType ? localStorage.loginType : null;
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          {loginType == 1 ? (
            <>
              <PrivateRoute1
                exact
                path="/dashboard-artist"
                component={DashboardArtist}
                user={token}
              />
              {console.log("load")}
              <PrivateRoute1
                exact
                path="/my-profile"
                component={MyProfile}
                user={token}
              />
              <PrivateRoute1
                exact
                path="/my-notifications"
                component={Notifications}
                user={token}
              />
              {token ? (
                <Redirect to="/dashboard-artist" />
              ) : (
                <Redirect to="/artist-login" />
              )}
              <Route exact path='/artist-login' component={ArtistLogin} />
              <Route exact path='/artist-otp' component={ArtistOtp} />

            </>
          ) : loginType == 2 ? (
            <>
              {console.log("loaded")}
              <PrivateRoute
                exact
                path="/dashboard"
                component={Dashboard}
                user={token}
              />
              <Route exact path="/layout/RtlLayout" component={RtlLayout} />

              <Route exact path="/widgets" component={Widgets} />
              {this.props.permissions.userManagement?.view ? (
                <PrivateRoute
                  exact
                  path="/user-management"
                  component={UserManagement}
                  user={token}
                />
              ) : null}
              {this.props.permissions.userManagement?.edit ? (
                <PrivateRoute
                  exact
                  path="/user/:id"
                  component={UserDetail}
                  user={token}
                />
              ) : null}
              <PrivateRoute
                exact
                path="/my-account"
                component={MyAccount}
                user={token}
              />
              {this.props.permissions.categoryManagement?.view ? (
                <PrivateRoute
                  exact
                  path="/categories-management"
                  component={CategoriesManagement}
                  user={token}
                />
              ) : null}
              {this.props.permissions.categoryManagement?.edit ? (
                <PrivateRoute
                  exact
                  path="/categories-management/artist-type-update/:id"
                  component={AddArtistType}
                  user={token}
                />
              ) : null}
              {this.props.permissions.newsletter?.view ? (
                <PrivateRoute
                  exact
                  path="/news-letter"
                  component={NewsLetter}
                  user={token}
                />
              ) : null}
              {this.props.permissions.enquiryManagement?.view ? (
                <PrivateRoute
                  exact
                  path="/enquiry-management"
                  component={EnquiryManagement}
                  user={token}
                />
              ) : null}
              {this.props.permissions.enquiryManagement?.view ? (
                <PrivateRoute
                  exact
                  path="/enquiry-management/:id"
                  component={Enquiries}
                  user={token}
                />
              ) : null}
              {this.props.permissions.categoryManagement?.add ? (
                <PrivateRoute
                  exact
                  path="/categories-management/artist-type-new/"
                  component={AddArtistType}
                  user={token}
                />
              ) : null}
              {this.props.permissions.categoryManagement?.add ? (
                <PrivateRoute
                  exact
                  path="/categories-management/specialty-new"
                  component={AddSpecialties}
                  user={token}
                />
              ) : null}
              {this.props.permissions.categoryManagement?.edit ? (
                <PrivateRoute
                  exact
                  path="/categories-management/specialty-update/:id"
                  component={AddSpecialties}
                  user={token}
                />
              ) : null}
              {this.props.permissions.categoryManagement?.add ? (
                <PrivateRoute
                  exact
                  path="/categories-management/tag-new"
                  component={AddTag}
                  user={token}
                />
              ) : null}
              {this.props.permissions.categoryManagement?.edit ? (
                <PrivateRoute
                  exact
                  path="/categories-management/tag-update/:id"
                  component={AddTag}
                  user={token}
                />
              ) : null}
              {this.props.permissions.categoryManagement?.add ? (
                <PrivateRoute
                  exact
                  path="/categories-management/genre-new"
                  component={AddGenre}
                  user={token}
                />
              ) : null}
              {this.props.permissions.categoryManagement?.edit ? (
                <PrivateRoute
                  exact
                  path="/categories-management/genre-update/:id"
                  component={AddGenre}
                  user={token}
                />
              ) : null}
              {this.props.permissions.artistManagement?.view ? (
                <PrivateRoute
                  exact
                  path="/artist-management"
                  component={ArtistList}
                  user={token}
                />
              ) : null}
              {this.props.permissions.supplierManagement?.view ? (
                <PrivateRoute
                  exact
                  path="/supplier-management"
                  component={SupplierList}
                  user={token}
                />
              ) : null}
              {this.props.permissions.artistManagement?.add ? (
                <PrivateRoute
                  exact
                  path="/add-artist"
                  component={AddArtist}
                  user={token}
                />
              ) : null}
              {this.props.permissions.supplierManagement?.add ? (
                <PrivateRoute
                  exact
                  path="/add-supplier"
                  component={AddSupplier}
                  user={token}
                />
              ) : null}
              {this.props.permissions.artistManagement?.view ? (
                <PrivateRoute
                  exact
                  path="/view-artist/:id"
                  component={AddArtist}
                  user={token}
                />
              ) : null}
              {this.props.permissions.supplierManagement?.view ? (
                <PrivateRoute
                  exact
                  path="/view-supplier/:id"
                  component={AddSupplier}
                  user={token}
                />
              ) : null}
              {this.props.permissions.artistManagement?.edit ? (
                <PrivateRoute
                  exact
                  path="/update-artist/:id"
                  component={AddArtist}
                  user={token}
                />
              ) : null}
              {this.props.permissions.supplierManagement?.edit ? (
                <PrivateRoute
                  exact
                  path="/update-supplier/:id"
                  component={AddSupplier}
                  user={token}
                />
              ) : null}
              {this.props.permissions.artistManagement?.edit ? (
                <PrivateRoute
                  exact
                  path="/manage-posts/:id"
                  component={CreateArtistPost}
                  user={token}
                />
              ) : null}
              {this.props.permissions.websiteManagement?.view ? (
                <PrivateRoute
                  exact
                  path="/website-management/section-management"
                  component={PageList}
                  user={token}
                />
              ) : null}
              {this.props.permissions.websiteManagement?.view ? (
                <PrivateRoute
                  exact
                  path="/website-management/create-menu"
                  component={CreateMenu}
                  user={token}
                />
              ) : null}
              {this.props.permissions.websiteManagement?.view ? (
                <PrivateRoute
                  exact
                  path="/website-management/update-menu/:id"
                  component={CreateMenu}
                  user={token}
                />
              ) : null}
              {this.props.permissions.websiteManagement?.view ? (
                <PrivateRoute
                  exact
                  path="/website-management/menu-management"
                  component={MenuList}
                  user={token}
                />
              ) : null}
              {this.props.permissions.websiteManagement?.view ? (
                <PrivateRoute
                  exact
                  path="/website-management/menu-management/:id/create-sub-menu"
                  component={CreateSubMenu}
                  user={token}
                />
              ) : null}
              {this.props.permissions.websiteManagement?.view ? (
                <PrivateRoute
                  exact
                  path="/website-management/menu-management/:id/update-sub-menu/:Mainid"
                  component={CreateSubMenu}
                  user={token}
                />
              ) : null}
              {this.props.permissions.websiteManagement?.view ? (
                <PrivateRoute
                  exact
                  path="/website-management/page-layout-management/create-page-layout"
                  component={CreatePageLayout}
                  user={token}
                />
              ) : null}
              {this.props.permissions.websiteManagement?.view ? (
                <PrivateRoute
                  exact
                  path="/website-management/page-layout-management/update-page-layout/:id"
                  component={CreatePageLayout}
                  user={token}
                />
              ) : null}
              {this.props.permissions.websiteManagement?.view ? (
                <PrivateRoute
                  exact
                  path="/website-management/page-layout-management"
                  component={PageLayoutManagement}
                  user={token}
                />
              ) : null}
              {this.props.permissions.websiteManagement?.add ? (
                <PrivateRoute
                  exact
                  path="/add-section"
                  component={CreatePage}
                  user={token}
                />
              ) : null}
              {this.props.permissions.websiteManagement?.edit ? (
                <PrivateRoute
                  exact
                  path="/update-section/:id"
                  component={CreatePage}
                  user={token}
                />
              ) : null}
              {this.props.permissions.subadmin?.view ? (
                <PrivateRoute
                  exact
                  path="/sub-admin-management"
                  component={SubAdminList}
                  user={token}
                />
              ) : null}
              {this.props.permissions.subadmin?.add ? (
                <PrivateRoute
                  exact
                  path="/add-subAdmin"
                  component={CreateSubadmin}
                  user={token}
                />
              ) : null}
              {this.props.permissions.subadmin?.edit ? (
                <PrivateRoute
                  exact
                  path="/update-subAdmin/:id"
                  component={CreateSubadmin}
                  user={token}
                />
              ) : null}
              {this.props.permissions.contentManagement?.view ? (
                <PrivateRoute
                  exact
                  path="/content-management"
                  component={ContentList}
                  user={token}
                />
              ) : null}
              {this.props.permissions.contentManagement?.add ? (
                <PrivateRoute
                  exact
                  path="/create-content"
                  component={CreateContent}
                  user={token}
                />
              ) : null}
              {this.props.permissions.contentManagement?.edit ? (
                <PrivateRoute
                  exact
                  path="/update-content/:id"
                  component={CreateContent}
                  user={token}
                />
              ) : null}

              {this.props.permissions.roles?.view ? (
                <PrivateRoute
                  exact
                  path="/roles-management"
                  component={RolesList}
                  user={token}
                />
              ) : null}
              {this.props.permissions.roles?.add ? (
                <PrivateRoute
                  exact
                  path="/add-role"
                  component={CreateRole}
                  user={token}
                />
              ) : null}
              {this.props.permissions.roles?.edit ? (
                <PrivateRoute
                  exact
                  path="/update-role/:id"
                  component={CreateRole}
                  user={token}
                />
              ) : null}

              {this.props.permissions.metaTagManagement?.view ? (
                <PrivateRoute
                  exact
                  path="/meta-tags"
                  component={MetaTagList}
                  user={token}
                />
              ) : null}
              {this.props.permissions.metaTagManagement?.edit ? (
                <PrivateRoute
                  exact
                  path="/meta-tags/:id"
                  component={TagDetail}
                  user={token}
                />
              ) : null}
              {/* <Route exact path="/artist-login" component={ArtistLogin} /> */}
              {/* <Route exact path="/login" component={Login} /> */}
              <Route exact path="/login" component={Login} />
              {token ? <Redirect to="/dashboard" /> : <Redirect to="/login" />}
            </>
          ) : loginType == 3 ? (
            <>
              <PrivateRoute1
                exact
                path="/supplier/my-profile"
                component={MyProfileSupplier}
                user={token}
              />
              {token ? (
                <Redirect to="/supplier/my-profile" />
              ) : (
                <Redirect to="/supplier-login" />
              )}
              <Route exact path='/supplier-login' component={SupplierLogin} />
              <Route exact path='/supplier-otp' component={SupplierOtp} />
            </>
          ) : null}


          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/artist-login" component={ArtistLogin} />
          <Route exact path="/artist-otp" component={ArtistOtp} />
          <Route exact path="/artist-forgot" component={ArtistForget} />
          <Route exact path="/artist-reset/:id" component={ArtistReset} />
          <Route exact path="/artist-register/:id" component={ArtistRegister} />
          <Route exact path="/apps/kanban-board" component={KanbanBoard} />
          <Route exact path="/apps/todo-list" component={TodoList} />
          <Route exact path="/apps/tickets" component={Tickets} />
          <Route exact path="/apps/chats" component={Chats} />
          <Route exact path="/artist-signup" component={ArtistLanding} />
          <Route exact path="/supplier-signup" component={SupplierLanding} />
          <Route exact path="/supplier-login" component={SupplierLogin} />
          <Route exact path="/supplier-otp" component={SupplierOtp} />
          <Route exact path="/supplier-forgot" component={SupplierForget} />
          <Route exact path="/supplier-register/:id" component={SupplierRegister} />
          <Route exact path="/supplier-reset/:id" component={SupplierReset} />
          <Route exact path="/resetpassword/:token" component={ResetPassword} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route path="/basic-ui/accordions" component={Accordions} />
          <Route path="/basic-ui/buttons" component={Buttons} />
          <Route path="/basic-ui/breadcrumbs" component={Breadcrumbs} />
          <Route path="/basic-ui/dropdowns" component={Dropdowns} />
          <Route path="/basic-ui/modals" component={Modals} />
          <Route path="/basic-ui/progressbar" component={Progress} />
          <Route path="/basic-ui/pagination" component={Paginations} />
          <Route path="/basic-ui/tabs" component={TabsPage} />
          <Route path="/basic-ui/typography" component={Typography} />
          <Route path="/basic-ui/tooltips" component={Tooltips} />
          <Route path="/basic-ui/popups" component={Popups} />

          <Route path="/advanced-ui/dragula" component={Dragula} />
          <Route path="/advanced-ui/clipboard" component={Clipboard} />
          <Route path="/advanced-ui/context-menu" component={ContextMenu} />
          <Route path="/advanced-ui/sliders" component={Sliders} />
          <Route path="/advanced-ui/carousel" component={Carousel} />
          <Route path="/advanced-ui/loaders" component={Loaders} />
          <Route path="/advanced-ui/tree-view" component={TreeView} />

          <Route
            path="/form-Elements/basic-elements"
            component={BasicElements}
          />
          <Route
            path="/form-Elements/advanced-elements"
            component={AdvancedElements}
          />
          <Route path="/form-Elements/validation" component={Validation} />
          <Route path="/form-Elements/wizard" component={Wizard} />

          <Route path="/tables/basic-table" component={BasicTable} />
          <Route path="/tables/data-table" component={DataTable} />
          <Route path="/tables/react-table" component={ReactTable} />
          <Route path="/tables/sortable-table" component={SortableTable} />

          <Route path="/maps/vector-map" component={VectorMap} />
          <Route path="/maps/simple-map" component={SimpleMap} />



          <Route path="/icons/mdi" component={Mdi} />
          <Route path="/icons/flag-icons" component={FlagIcons} />
          <Route path="/icons/font-awesome" component={FontAwesome} />
          <Route path="/icons/simple-line" component={SimpleLine} />
          <Route path="/icons/themify" component={Themify} />
          <Route path="/icons/typicons" component={TypIcons} />

          <Route path="/text-editors" component={TextEditors} />
          <Route path="/code-editor" component={CodeEditor} />

          <Route path="/icons/themify" component={Themify} />

          <Route path="/charts/chart-js" component={ChartJs} />
          <Route path="/charts/c3-chart" component={C3Charts} />
          <Route path="/charts/chartist" component={Chartist} />
          <Route path="/charts/google-charts" component={GoogleCharts} />
          <Route path="/charts/sparkline-charts" component={SparkLineCharts} />
          <Route path="/charts/guage-chart" component={GuageChart} />


          <Route path="/user-pages/login-2" component={Login2} />
          <Route path="/user-pages/register-1" component={Register1} />
          <Route path="/user-pages/register-2" component={Register2} />
          <Route path="/user-pages/lockscreen" component={Lockscreen} />

          <Route path="/error-pages/error-404" component={Error404} />
          <Route path="/error-pages/error-500" component={Error500} />

          <Route path="/general-pages/blank-page" component={BlankPage} />
          <Route path="/general-pages/landing-page" component={LandingPage} />
          <Route path="/general-pages/profile" component={Profile} />
          <Route path="/general-pages/faq-1" component={Faq} />
          <Route path="/general-pages/faq-2" component={Faq2} />
          <Route path="/general-pages/news-grid" component={NewsGrid} />
          <Route path="/general-pages/timeline" component={Timeline} />
          <Route
            path="/general-pages/search-results"
            component={SearchResults}
          />
          <Route path="/general-pages/portfolio" component={Portfolio} />
          <Route path="/general-pages/user-listing" component={UserListing} />

          <Route path="/ecommerce/invoice" component={Invoice} />
          <Route path="/ecommerce/pricing" component={Pricing} />
          <Route
            path="/ecommerce/product-catalogue"
            component={ProductCatalogue}
          />
          <Route path="/ecommerce/project-list" component={ProjectList} />
          <Route path="/ecommerce/orders" component={Orders} />

          <Route path="/apps/email" component={Email} />
          <Route path="/apps/calendar" component={Calendar} />
          <Route path="/apps/gallery" component={Gallery} />

          {/* <Redirect to="/login" /> */}
          {token ? <Redirect to="/dashboard" /> : <Redirect to="/login" />}
          <Route path="*" component={"/"} />
        </Switch>
      </Suspense>
    );
  }
}
function mapStateToProps(state) {
  return {
    permissions: state.PermissionReducer.permissionDetails,
  };
}
export default connect(mapStateToProps, {})(AppRoutes);
